import React from 'react';


function Login() {

        return <>
               <div className = "content">
 

               <img src="img/logo.jpg" title="lilodesign" width="144" height="86" alt = ""/>
                            <br/>
                            <img src="img/bullet_53.gif" alt = ""></img>
                            <a href="mailto:info@lilodesign.be">Contacteer</a>

<form method='post' action= 'redirect.php'> 
<input type='text'  size='13' name='Fld_User' placeholder='User' data-autosize-input='{space: 10}'/>
<br/>
<br/>
<input type='password' size='13' name='Fld_Pass' placeholder='Pass' data-autosize-input='{space:10}'/>
<br/>
<br/>
<input type='submit' name='submit' value='Login'/>
</form>	
<br/>                  
</div> 
               </>;
}

export default Login;