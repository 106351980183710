import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './css/css_lilo.css';

import Home  from "./components/Home" 
import Login  from "./components/Login" 

function App() {
  return (
<BrowserRouter>
    <Routes>
      <Route path='' element = {<Home/>}/> 
      <Route path='Home' element = {<Home/>}/>
      <Route path='Login' element = {<Login/>}/>
      <Route path='*' element = {<Home/>}/>
    </Routes>  
</BrowserRouter>
 )
}


export default App;
