import React from 'react';
import { Outlet, Link } from "react-router-dom";


function Home() {

        return <>
               <div className = "content">
               <img src="img/logo.jpg" title="lilodesign" width="144" height="86" alt = ""/>
                            <br/>
                            <img src="img/bullet_53.gif" alt = ""></img>
                            <a href="mailto:info@lilodesign.be">Contacteer</a>
               </div>
               <div className="login"  >
               <Link to="/Login">Login for clients, admins and developers</Link>
              </div>  
               </>;
}

export default Home;